import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { Link } from 'gatsby';
// import { graphql, Link } from 'gatsby';

import PageLayout from './PageLayout';
import { blog } from '../seoConfig';
import P from '../components/_common/P';
import { graphql } from 'gatsby';
import SubpageTopCover from '../components/SubpageTopCover';
// import AnimatedOnScroll from '../components/_common/AnimatedOnScroll';
import Container from '../components/_common/Container';
import TextContainer from '../components/_common/TextContainer';
// import { colors, fonts } from '../globalStyles';

// const ExtendedH2 = styled(H2)`
// 	font-size: 40px;
// 	color: ${colors.blackTitle};
// 	font-family: ${fonts.robotoRegular};
// 	text-align: center;
// 	font-weight: 700;
// 	margin: 68px 0 35px;
// `;

const StyledArticleWrapper = styled.div`
	.center {
		text-align: center;
	}
	img {
		max-width: 100%;
	}
	.container-fluid{
		margin: 75px 0;
	}
	.container{
		margin: 75px auto;
	}
	${P} + div {
		margin-top: 65px;
	}
	/* =WordPress Core
-------------------------------------------------------------- */
	.alignnone {
		margin: 5px 20px 20px 0;
	}
	
	.aligncenter,
	div.aligncenter {
		display: block;
		margin: 5px auto 5px auto;
	}
	
	.alignright {
		float:right;
		margin: 5px 0 20px 20px;
	}
	
	.alignleft {
		float: left;
		margin: 5px 20px 20px 0;
	}
	
	a img.alignright {
		float: right;
		margin: 5px 0 20px 20px;
	}
	
	a img.alignnone {
		margin: 5px 20px 20px 0;
	}
	
	a img.alignleft {
		float: left;
		margin: 5px 20px 20px 0;
	}
	
	a img.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	
	.wp-caption {
		background: #fff;
		border: 1px solid #f0f0f0;
		max-width: 96%; /* Image does not overflow the content area */
		padding: 5px 3px 10px;
		text-align: center;
	}
	
	.wp-caption.alignnone {
		margin: 5px 20px 20px 0;
	}
	
	.wp-caption.alignleft {
		margin: 5px 20px 20px 0;
	}
	
	.wp-caption.alignright {
		margin: 5px 0 20px 20px;
	}
	
	.wp-caption img {
		border: 0 none;
		height: auto;
		margin: 0;
		max-width: 98.5%;
		padding: 0;
		width: auto;
	}
	
	.wp-caption p.wp-caption-text {
		font-size: 11px;
		line-height: 17px;
		margin: 0;
		padding: 0 4px 5px;
	}
	
	/* Text meant only for screen readers. */
	.screen-reader-text {
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute !important;
		width: 1px;
		word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
	}
	
	.screen-reader-text:focus {
		background-color: #eee;
		clip: auto !important;
		clip-path: none;
		color: #444;
		display: block;
		font-size: 1em;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000;
	/* Above WP toolbar. */
	}
`;
// const headingLevelToComponentMapping = {
// 	1: H1,
// 	2: ExtendedH2,
// 	3: H3,
// 	4: H4,
// 	5: H5,
// 	6: H6,
// };
// const LinkRenderer = (props) => <Link {...props} to={props.href} target="_blank" rel="noopener noreferrer" />;
// const HeadingRenderer = (props) => {
// 	const Heading = headingLevelToComponentMapping[props.level];
// 	return (
// 		<AnimatedOnScroll animateOut="none">
// 			<Heading {...props} />
// 		</AnimatedOnScroll>
// 	);
// };
// const ParagraphRenderer = (props) => <P {...props} />;
// const renderers = {
// 	link: LinkRenderer,
// 	heading: HeadingRenderer,
// 	paragraph: ParagraphRenderer,
// };
const ArticleLayout = ({ location, data }) => {
	return (
		<PageLayout seoConfig={blog} location={location}>
			<StyledArticleWrapper>
				<SubpageTopCover showOverlay backgroundImg={data.wordpressPost.featured_media.localFile.childImageSharp.fluid}>
					{data.wordpressPost.title}
				</SubpageTopCover>
				<Container>
					<TextContainer textAlign="left">
						<div dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }} />
					</TextContainer>
				</Container>
			</StyledArticleWrapper>
		</PageLayout>
	);
};

ArticleLayout.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
};

export const query = graphql`
    query ArticleTemplate($id: String!){
        wordpressPost(id: {eq: $id}){
            id
            slug
            content
            title
            featured_media{
                localFile{
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;
// export const query = graphql`
//     query ArticleTemplate($id: String!) {
//         strapiArticle(id: {eq: $id}) {
//             title {
// 								rendered
// 						}
//             content {
// 								rendered
// 						}
//             date
//             hero_image {
//                 childImageSharp {
//                     fluid(maxWidth: 1920, quality: 80) {
//                         ...GatsbyImageSharpFluid_withWebp
//                     }
//                 }
//             }
//         }
//     }
// `;

export default ArticleLayout;
